<template>
  <v-row
    class="zoomSliderContainer full-view-port-height justify-space-around"
    dense
  >
    <v-col cols="4" />
    <v-col cols="4" class="align-self-center">
      <v-slider
        class="zoomSlider align-self-center"
        hint="Adjust Zoom"
        discrete
        :max="zooms[zooms.length - 1]"
        :min="zooms[0]"
        :value="currentZoom"
        vertical
        color="grey darken-2"
        track-color="grey darken-2"
        @change="changePageZoom"
      />
    </v-col>
    <v-col cols="4" />
  </v-row>
</template>
<script>
import { debounce } from "lodash";

export default {
  name: "ZoomSlider",
  props: {
    flipbook: {
      type: Object,
    },
  },
  data: function () {
    return {
      currentZoom: 1,
      zooms: [1, 2, 3, 4],
    };
  },
  watch: {
    "flipbook.zoom": debounce(
      function () {
        this.currentZoom = Math.floor(this.flipbook.zoom);
        this.tweakViewPort(this.currentZoom);
      },
      1000,
      {
        leading: false,
        trailing: true,
      }
    ),
  },
  mounted() {
    this.currentZoom = Math.floor(this.flipbook?.zoom || 1);
    this.tweakViewPort(this.currentZoom);
  },
  methods: {
    tweakViewPort(number) {
      const root = document.documentElement;
      if (number > 1) {
        root.style.setProperty("--flipbook-top-margin", "0px");
        root.style.setProperty("--flipbook-min-width", "100vw");
        root.style.setProperty("--flipbook-min-height", "100vh");
      } else {
        root.style.setProperty("--flipbook-top-margin", "10vh");
        root.style.setProperty("--flipbook-min-width", "85vw");
        root.style.setProperty("--flipbook-min-height", "85vh");
      }
    },
    changePageZoom: function (number) {
      this.currentZoom = number;
      this.tweakViewPort(number);
      const flipbook = this.flipbook;
      this.$nextTick(() => {
        /*  The zoomTo is not exposed but is being used since we don't want the zoom provided by the library and so
        cannot use zoomIn and zoomOut */
        flipbook.zoomTo(number);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.zoomSliderContainer {
  position: fixed;
  top: 20vh;
  right: 2vw;
}
</style>
